@tailwind base;
@tailwind components;

@import './Tailwind/helpers.css';
@import './Tailwind/buttons.css';
@import './Tailwind/slider.css';
@import './Tailwind/article.css';
@import './Tailwind/footer.css';
@import './Tailwind/episerverforms.css';
@import './Tailwind/tidliginn.css';
@import './Tailwind/interactivematrix.css';

@tailwind utilities;

@layer base {
    h1, .h1 {
      @apply text-3xl;
    }
    h2, .h2 {
      @apply text-2xl;
    }
    h3, .h3 {
      @apply text-xl;
    }
    h4, .h4 {
      @apply text-lg;
    }
  }

