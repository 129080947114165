<template>
    <div
        v-epi-edit="pagePropertyName"
        v-if="image"
        class="container relative flex items-center"
    >
        <div v-if="imageLink" class="absolute w-full text-white text-center py-10 bg-black bg-opacity-50">
            <a
                v-if="alternativeText"
                :href="imageLink"
                :title="alternativeText"
                class="bg-green-500 hover:bg-green-100 text-white hover:text-green-900 py-4 px-10 rounded-lg font-bold text-lg tracking-wider"
            >
                {{ alternativeText }}

                <font-awesome-icon icon="angle-right" />
            </a>
        </div>
        <img
            :src="image"
            :alt="alternativeText"
            class="o-image"
        />
    </div>
</template>

<script>
export default {
    props: ['image', 'alternativeText', 'pagePropertyName', 'imageLink'],
    components: {
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss">
    .o-image {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
    }
</style>
