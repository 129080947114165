@import '~swiper/css/swiper.css';

.swiper-button::after {
    @apply text-lg md:text-xl;
}

.swiper-pagination-bullet {
    @apply w-2 h-2 md:w-4 md:h-4 ml-2 mt-4 opacity-100 border border-gray-400 bg-gray-50;
}

.swiper-pagination-bullet:hover {
    @apply border-gray-600 bg-white;
}

.swiper-pagination-bullet-active {
    @apply border border-green-800 bg-green-500;
}

.swiper-pagination-bullet-active:hover {
    @apply border border-green-600 bg-green-300;
}




