.im--pane__table {
    @apply border-gray-200 border-4 p-4 md:p-6 mb-4 lg:mb-8 text-sm lg:text-base;
}

.im--pane__content {
    @apply border-gray-200 border-4 p-4 md:p-6 mb-4 lg:mb-8 text-sm lg:text-base;
}

.im--row {
    @apply md:flex p-2;
}

.im--row__cell {
    @apply w-full md:w-1/4 p-2;
}

.im--button {
    @apply text-gray-800 text-center p-2 shadow border border-black w-full h-full flex;
}

.im--button svg {
    @apply self-center mr-2;
}

.im--button:hover,
.im--button:focus {
    @apply text-black;
}

.im--button__simple {
    @apply text-gray-800 border border-black p-2 w-full h-full flex text-center;
}

.im--button__simple:hover,
.im--button__simple:focus {
    @apply bg-white text-black;
}

/* Theme - yellow */
.im--row.im--theme__yellow {
    @apply bg-yellow-100;
}

.im--theme__yellow .im--button {
    @apply from-yellow-200 to-yellow-500;
}

.im--theme__yellow .im--button:hover,
.im--theme__yellow .im--button:focus {
    @apply from-yellow-100 to-yellow-300 shadow-sm;
}

/* Theme - yellow */
.im--row.im--theme__green {
    @apply bg-green-100;
}

.im--theme__green .im--button {
    @apply from-green-200 to-green-500;
}

.im--theme__green .im--button:hover,
.im--theme__green .im--button:focus {
    @apply from-green-100 to-green-300 shadow-sm;
}

/* Theme - blue */
.im--row.im--theme__blue {
    @apply bg-blue-50;
}

.im--theme__blue .im--button {
    @apply from-blue-200 to-blue-400;
}

.im--theme__blue .im--button:hover,
.im--theme__blue .im--button:focus {
    @apply from-blue-100 to-blue-300 shadow-sm;
}

/* Theme - darkblue */
.im--row.im--theme__darkblue {
    @apply bg-blue-300;
}

.im--theme__darkblue .im--button {
    @apply from-blue-500 to-blue-700 text-white;
}

.im--theme__darkblue .im--button:hover,
.im--theme__darkblue .im--button:focus {
    @apply from-blue-300 to-blue-600 shadow-sm;
}


/* Theme - red */
.im--row.im--theme__red {
    @apply bg-red-50;
}

.im--theme__red .im--button {
    @apply from-red-200 to-red-400;
}

.im--theme__red .im--button:hover,
.im--theme__red .im--button:focus {
    @apply from-red-100 to-red-300 shadow-sm;
}

/* Theme - orange */
.im--row.im--theme__orange {
    @apply bg-orange-100;
}

.im--theme__orange .im--button {
    @apply from-orange-200 to-orange-500;
}

.im--theme__orange .im--button:hover,
.im--theme__orange .im--button:focus {
    @apply from-orange-100 to-orange-300 shadow-sm;
}

/* Theme - purple */
.im--row.im--theme__purple {
    @apply bg-purple-50;
}

.im--theme__purple .im--button {
    @apply from-purple-100 to-purple-400;
}

.im--theme__purple .im--button:hover,
.im--theme__purple .im--button:focus {
    @apply from-purple-100 to-purple-300 shadow-sm;
}

/* Theme - gray */
.im--row.im--theme__gray {
    @apply bg-gray-50;
}

.im--theme__gray .im--button {
    @apply from-gray-200 to-gray-400;
}

.im--theme__gray .im--button:hover,
.im--theme__gray .im--button:focus {
    @apply from-gray-100 to-gray-300 shadow-sm;
}

/* Theme - brown */
.im--row.im--theme__brown {
    @apply bg-brown-50;
}

.im--theme__brown .im--button {
    @apply from-brown-200 to-brown-400;
}

.im--theme__brown .im--button:hover,
.im--theme__brown .im--button:focus {
    @apply from-brown-100 to-brown-300 shadow-sm;
}

/* Theme - violet */
.im--row.im--theme__violet {
    @apply bg-violet-50;
}

.im--theme__violet .im--button {
    @apply from-violet-200 to-violet-400;
}

.im--theme__violet .im--button:hover,
.im--theme__violet .im--button:focus {
    @apply from-violet-100 to-violet-300 shadow-sm;
}
