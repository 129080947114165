:root {
    --tidliginn-theme-blue: #0057AE;
    --tidliginn-theme-red: #8D1B21;
    --tidliginn-theme-darkblue: #162059;
    --tidliginn-theme-orange: #C84F29;
    --tidliginn-theme-purple: #7C4A74;
    --tidliginn-theme-green: #46850B;
}

/* Blue */
.tidliginn-theme-blue-border {
    border-color: var(--tidliginn-theme-blue);
}

.tidliginn-theme-blue-bg {
    background-color: var(--tidliginn-theme-blue);
}

/* Red */
.tidliginn-theme-red-border {
    border-color: var(--tidliginn-theme-red);
}

.tidliginn-theme-red-bg {
    background-color: var(--tidliginn-theme-red);
}

/* Dark Blue */
.tidliginn-theme-darkblue-border {
    border-color: var(--tidliginn-theme-darkblue);
}

.tidliginn-theme-darkblue-bg {
    background-color: var(--tidliginn-theme-darkblue);
}

/* Orange */
.tidliginn-theme-orange-border {
    border-color: var(--tidliginn-theme-orange);
}

.tidliginn-theme-orange-bg {
    background-color: var(--tidliginn-theme-orange);
}

/* Purple */
.tidliginn-theme-purple-border {
    border-color: var(--tidliginn-theme-purple);
}

.tidliginn-theme-purple-bg {
    background-color: var(--tidliginn-theme-purple);
}

/* Green */
.tidliginn-theme-green-border {
    border-color: var(--tidliginn-theme-green);
}

.tidliginn-theme-green-bg {
    background-color: var(--tidliginn-theme-green);
}

/* Gray */
.tidliginn-theme-gray-border {
    @apply border-gray-600;
}

.tidliginn-theme-gray-bg {
    @apply bg-gray-600;
}

/* Yellow */
.tidliginn-theme-yellow-border {
    @apply border-yellow-600;
}

.tidliginn-theme-yellow-bg {
    @apply bg-yellow-600;
}

/* Yellow */
.tidliginn-theme-yellow-border {
    @apply border-yellow-600;
}

.tidliginn-theme-yellow-bg {
    @apply bg-yellow-600;
}

/* Brown */
.tidliginn-theme-brown-border {
    @apply border-brown-600;
}

.tidliginn-theme-brown-bg {
    @apply bg-brown-600;
}

/* Violet */
.tidliginn-theme-violet-border {
    @apply border-violet-600;
}

.tidliginn-theme-violet-bg {
    @apply bg-violet-600;
}
