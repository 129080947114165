/* BREADCRUMBS */
.breadcrumbs > li:after {
    content: ">";
    @apply absolute pl-2;
}
.breadcrumbs > li:last-child:after {
    @apply hidden;
}

/* Content in standard page */
.main-body a {
    @apply text-blue-600 underline;
}

.main-body a:hover {
    @apply text-red-600;
}

.main-body a:active {
    @apply text-red-600 no-underline;
}

.main-body p {
    @apply my-4;
}

.main-body ol {
    @apply list-decimal list-inside my-4;
}

.main-body ul {
    @apply list-disc list-inside my-4;
}

body {
    overflow-y: scroll;
}