<template>
    <div class="c_main-menu relative py-6 ml-4 w-14 lg:w-28"
            :class="[menuOpen ? 'c_main-menu--open' : '']"
            v-click-outside="closeMenu">
        <button class="btn btn-tundora p-2 w-14 lg:w-28 h-14"
                @click="toggleMenu"
                title="Trykk for � �pne/lukke menyen">
            <span class="hidden lg:inline-block">MENY</span>
            <font-awesome-icon v-if="!menuOpen" icon="bars" class="ml-0 lg:ml-1" />
            <font-awesome-icon v-if="menuOpen" icon="times" class="ml-0 lg:ml-1" />
        </button>
        <div class="absolute z-50 right-0 mt-2 bg-tundora-600 w-80 linear transition-scale origin-top duration-200 overflow-hidden"
                :class="[menuOpen ? 'shadow-2xl menuOpened' : 'menuClosed']">
            <ul class="list-none m-0 p-0 border">
                <li v-for="item in menu" :key="item.url"
                    class="border-b border-tundora-300 text-tundora-100 hover:bg-tundora-400 text-sm">
                    <a class="block p-4" :href="item.url">{{ item.text }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
    props: ['menu'],
    data() {
        return {
            menuOpen: false,
            icon: 'bars'
        };
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
            this.icon = this.menuOpen ? 'times' : 'bars';
        },
        closeMenu() {
            this.menuOpen = false;
            this.icon = 'bars';
        }
    },
    directives: {
        ClickOutside
    }
};
</script>

<style scoped>
    .menuOpened {
        transform: scaleY(1);
    }
    .menuClosed {
        transform: scaleY(0);
    }
</style>
