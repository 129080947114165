.im--pane__table {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-width: 4px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

@media (min-width: 768px) {
  .im--pane__table {
    padding: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .im--pane__table {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
  }
}

.im--pane__content {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-width: 4px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

@media (min-width: 768px) {
  .im--pane__content {
    padding: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .im--pane__content {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
  }
}

.im--row {
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .im--row {
    display: flex;
  }
}

.im--row__cell {
  padding: 0.5rem;
  width: 100%;
}

@media (min-width: 768px) {
  .im--row__cell {
    width: 25%;
  }
}

.im--button {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 1px;
  display: flex;
  height: 100%;
  padding: 0.5rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  width: 100%;
}

.im--button svg {
  align-self: center;
  margin-right: 0.5rem;
}

.im--button:hover, .im--button:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.im--button__simple {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 1px;
  display: flex;
  height: 100%;
  padding: 0.5rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  width: 100%;
}

.im--button__simple:hover, .im--button__simple:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

/* Theme - yellow */

.im--row.im--theme__yellow {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 250, 235, var(--tw-bg-opacity));
}

.im--theme__yellow .im--button {
  --tw-gradient-from: #fff2cc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 242, 204, 0));
  --tw-gradient-to: #fecb32;
}

.im--theme__yellow .im--button:hover, .im--theme__yellow .im--button:focus {
  --tw-gradient-from: #fffaeb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 250, 235, 0));
  --tw-gradient-to: #ffeaad;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - yellow */

.im--row.im--theme__green {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 249, 237, var(--tw-bg-opacity));
}

.im--theme__green .im--button {
  --tw-gradient-from: #e2f0d1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 240, 209, 0));
  --tw-gradient-to: #8ac247;
}

.im--theme__green .im--button:hover, .im--theme__green .im--button:focus {
  --tw-gradient-from: #f3f9ed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 249, 237, 0));
  --tw-gradient-to: #d0e7b5;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - blue */

.im--row.im--theme__blue {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 249, 252, var(--tw-bg-opacity));
}

.im--theme__blue .im--button {
  --tw-gradient-from: #c7e0ee;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 224, 238, 0));
  --tw-gradient-to: #61a8cf;
}

.im--theme__blue .im--button:hover, .im--theme__blue .im--button:focus {
  --tw-gradient-from: #e8f3f8;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(232, 243, 248, 0));
  --tw-gradient-to: #a5cde3;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - darkblue */

.im--row.im--theme__darkblue {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 205, 227, var(--tw-bg-opacity));
}

.im--theme__darkblue .im--button {
  --tw-gradient-from: #1d82ba;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 130, 186, 0));
  --tw-gradient-to: #16628c;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.im--theme__darkblue .im--button:hover, .im--theme__darkblue .im--button:focus {
  --tw-gradient-from: #a5cde3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 205, 227, 0));
  --tw-gradient-to: #1a75a7;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - red */

.im--row.im--theme__red {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 245, 245, var(--tw-bg-opacity));
}

.im--theme__red .im--button {
  --tw-gradient-from: #fccfcc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 207, 204, 0));
  --tw-gradient-to: #f77970;
}

.im--theme__red .im--button:hover, .im--theme__red .im--button:focus {
  --tw-gradient-from: #feeceb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 236, 235, 0));
  --tw-gradient-to: #fab2ad;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - orange */

.im--row.im--theme__orange {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 233, var(--tw-bg-opacity));
}

.im--theme__orange .im--button {
  --tw-gradient-from: #fbe1c9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 225, 201, 0));
  --tw-gradient-to: #f08826;
}

.im--theme__orange .im--button:hover, .im--theme__orange .im--button:focus {
  --tw-gradient-from: #fef3e9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 233, 0));
  --tw-gradient-to: #f9cfa8;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - purple */

.im--row.im--theme__purple {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 244, 248, var(--tw-bg-opacity));
}

.im--theme__purple .im--button {
  --tw-gradient-from: #f8eaf1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 234, 241, 0));
  --tw-gradient-to: #cd6b9c;
}

.im--theme__purple .im--button:hover, .im--theme__purple .im--button:focus {
  --tw-gradient-from: #f8eaf1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 234, 241, 0));
  --tw-gradient-to: #e2aac7;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - gray */

.im--row.im--theme__gray {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.im--theme__gray .im--button {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0));
  --tw-gradient-to: #9ca3af;
}

.im--theme__gray .im--button:hover, .im--theme__gray .im--button:focus {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0));
  --tw-gradient-to: #d1d5db;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - brown */

.im--row.im--theme__brown {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 246, 246, var(--tw-bg-opacity));
}

.im--theme__brown .im--button {
  --tw-gradient-from: #dad4d1;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(218, 212, 209, 0));
  --tw-gradient-to: #98867f;
}

.im--theme__brown .im--button:hover, .im--theme__brown .im--button:focus {
  --tw-gradient-from: #f0eeed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(240, 238, 237, 0));
  --tw-gradient-to: #c4bab6;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Theme - violet */

.im--row.im--theme__violet {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 247, 248, var(--tw-bg-opacity));
}

.im--theme__violet .im--button {
  --tw-gradient-from: #e2d5de;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(226, 213, 222, 0));
  --tw-gradient-to: #ae88a2;
}

.im--theme__violet .im--button:hover, .im--theme__violet .im--button:focus {
  --tw-gradient-from: #f3eef2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 238, 242, 0));
  --tw-gradient-to: #d1bbca;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
