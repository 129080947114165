.article p,
.article ul,
.article ol,
.article table,
.tekst p,
.tekst ul,
.tekst ol,
.tekst table {
    @apply mb-6 mt-0;
}

.article ul,
.article ol,
.tekst ul,
.tekst ol {
    @apply list-outside ml-4;
}

.article ul,
.tekst ul {
    @apply list-disc;
}

.article ol,
.tekst ol {
    @apply list-decimal;
}


.article p,
.tekst p {
    @apply leading-6;
}

.article table,
.tekst table {
    @apply w-full;
}

.article table[border="1"],
.article table[border="1"] td,
.article table[border="1"] th,
.tekst table[border="1"],
.tekst table[border="1"] td,
.tekst table[border="1"] th {
    @apply border border-gray-900;
}

.article th,
.article td,
.tekst th,
.tekst td {
    @apply p-1;
}

.article th > p,
.article td > p,
.tekst th > p,
.tekst td > p {
    @apply m-0;
}

.articlebox a {
    @apply text-blue-600 underline;
}

.articlebox a:hover {
    @apply no-underline;
}

.articlebox .navigation a {
    @apply no-underline text-gray-700 bg-white;
}

.articlebox .navigation a.selected {
    @apply bg-gray-700 text-white;
}

.articlebox .navigation a:hover,
.articlebox .navigation a:focus {
    @apply no-underline text-white bg-gray-700;
}

.article a,
.tekst a {
    @apply underline text-blue-700;
}

.article a:hover,
.tekst a:hover {
    @apply text-green-700;
}

.article a:active,
.tekst a:active {
    @apply text-red-700;
}

.article p > font {
    font-size: inherit;
}
