<template>
    <div class="w-full p-5 mb-6 bg-white text-sm articlebox">
        <div v-if="headerRight" class="flex">
            <h4 v-epi-edit="headerPropname" v-if="header" class="w-1/2 font-semibold border-b mb-1 text-lg text-left" :class="`text-${color}-600`" v-text="header"></h4>
            <h4 v-epi-edit="headerPropname" v-if="headerRight" class="w-1/2 font-semibold border-b mb-1 text-lg text-right" :class="`text-${color}-600`" v-text="headerRight"></h4>
        </div>
        <div v-else>
            <h4 v-epi-edit="headerPropname" v-if="header" class="font-semibold border-b mb-1 text-lg" :class="`text-${color}-600`" v-text="header"></h4>
        </div>
        <slot></slot>
        <div v-if="textRight" class="flex">
            <div v-if="text" class="w-1/2 text-left" v-text="text"></div>
            <div v-if="textRight" class="w-1/2 text-right" v-text="textRight"></div>
        </div>
        <div v-else>
            <div v-if="text" class="text-left" v-text="text"></div>
        </div>
        <div v-epi-edit="htmlPropname" v-if="html" v-html="html"></div>
        <div v-if="!html" v-epi-edit="htmlPropname"></div>
        </div>
</template>

<script>
export default {
    props: {
        header: {
            type: String
        },
        headerRight: {
            type: String
        },
        text: {
            type: String
        },
        textRight: {
            type: String
        },
        html: {
        },
        colorTheme: {
            type: String,
            'default': 'green'
        },
        headerPropname: {
            type: String,
            'default': 'none',
            require: false
        },
        htmlPropname: {
            type: String,
            require: false
        },
        textPropname: {
            type: String,
            require: false
        }
    },
    computed: {
        color() {
            return this.colorTheme === null ? 'green' : this.colorTheme;
        }
    },
};
</script>
<style scoped>
    .articlebox p{
        @apply mb-2;
    }
</style>
