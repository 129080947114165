.EPiServerForms label,
.EPiServerForms input,
.EPiServerForms .Form__Element .Form__Element__Caption {
    @apply block mb-1 w-full;
}

.EPiServerForms .FormChoice__Input.FormChoice__Input--Radio {
    @apply inline-block w-auto;
}

.EPiServerForms label,
.EPiServerForms legend {
    @apply font-bold;
}

.EPiServerForms legend {
    @apply text-lg;
}

.EPiServerForms input[type=text],
.EPiServerForms input[type=password]{
    @apply border border-gray-600 p-2;
}

.EPiServerForms .ValidationFail input[type=text],
.EPiServerForms .ValidationFail input[type=password]{
    @apply  border-red-600;
}


.EPiServerForms .FormSubmitButton {
    @apply px-4 py-2 flex rounded-full text-white text-center cursor-pointer bg-green-700;
}

.EPiServerForms .FormSubmitButton:hover,
.EPiServerForms .FormSubmitButton:focus {
    @apply bg-blue-700;
}

.EPiServerForms .FormSubmitButton:active {
    @apply bg-red-700;
}
