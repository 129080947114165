@import '~swiper/css/swiper.css';

.swiper-button::after {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (min-width: 768px){
  .swiper-button::after{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.swiper-pagination-bullet {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  border-width: 1px;
  height: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 1rem;
  opacity: 1;
  width: 0.5rem;
}

@media (min-width: 768px){
  .swiper-pagination-bullet{
    height: 1rem;
    width: 1rem;
  }
}

.swiper-pagination-bullet:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.swiper-pagination-bullet-active {
  --tw-bg-opacity: 1;
  background-color: rgba(138, 194, 71, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(83, 116, 43, var(--tw-border-opacity));
  border-width: 1px;
}

.swiper-pagination-bullet-active:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(208, 231, 181, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(124, 175, 64, var(--tw-border-opacity));
  border-width: 1px;
}




