<template>
    <DefaultTemplate :model="model">
        <Container>
            <div class="container">
                <div class="mt-12 mb-8">
                    <Breadcrumbs :breadcrumbs="model.breadcrumbs" className="text-grey-400" />
                </div>
            </div>
            <div class="container">
                <h1 class="mb-8 font-bold">{{ model.name }}</h1>
                <div v-html="model.mainBody" class="mb-8 main-body text-lg"></div>
            </div>

            <!-- SØK -->
            <div class="flex flex-col w-full">
                <div class="w-full md:w-auto relative flex items-center mb-8 px-4">
                    <button class="absolute left-4 top-0 p-4 text-gray-600 hover:text-black"
                            v-on:click="handleSearch">
                        <font-awesome-icon icon="search" />
                    </button>
                    <input type="text"
                           name="searchQuery"
                           autocomplete="off"
                           class="rounded-lg pl-10 p-4 bg-white border-2 w-full"
                           v-model="searchQuery"
                           placeholder="Søk blant tiltakene her"
                           v-on:change="handleSearch" />
                    <button class="absolute right-0 right-4 p-4 text-gray-600 hover:text-black"
                            v-on:click="handleClear">
                        <font-awesome-icon icon="times" />
                    </button>
                </div>
            </div>

            <div v-if="measures.length === 0 && searchEmpty === false" class="container flex flex-col lg:flex-row">
                <!-- VENSTRE INNHOLD-->
                <div class="flex flex-col lg:w-4/6 w-full">
                    <!-- FANER -->
                    <div class="flex flex-col w-full">
                        <ul class="flex flex-wrap">
                            <li v-for="tab in tabs"
                                :key="tab.id"
                                class="w-full md:w-1/2 pb-8 pr-4">
                                <button class="text-2xl w-full p-2 hover:bg-blue-500 font-semibold text-white rounded shadow:md"
                                        :class="[selectedTabId === tab.id ? 'bg-blue-500' : 'bg-green-500']"
                                        @click="changeTab(tab.id)">
                                    {{ tab.title }}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!-- TILTAKOVERSIKT ETTER VALG AV FANE -->
                    <div class="flex flex-col w-full">
                        <!-- List county -->
                        <ul v-if="selectedTabId === 1" class="flex flex-wrap">
                            <li v-for="county in model.fylker" :key="county.id" class="w-1/2 lg:w-1/4 py-4 pr-4">
                                <tiltak-list-item-county :item="county" @clicked="selectMainMeasure" />
                            </li>
                        </ul>
                        <!-- // List county -->
                        <!-- List arena -->
                        <ul v-if="selectedTabId === 2" class="flex flex-wrap">
                            <li v-for="(arena, index) in model.arenaer" :key="index" class="w-1/2 lg:w-1/4 py-4 pr-4">
                                <tiltak-list-item :item="arena" :type="'arenas'" @clicked="selectMainMeasure" />
                            </li>
                        </ul>
                        <!-- // List arena -->
                        <!-- List target group -->
                        <ul v-if="selectedTabId === 3" class="flex flex-wrap">
                            <li v-for="(targetgroup, index) in model.maalgrupper" :key="index" class="w-1/2 lg:w-1/4 py-4 pr-4">
                                <tiltak-list-item :item="targetgroup" :type="'targetgroups'" @clicked="selectMainMeasure" />
                            </li>
                        </ul>
                        <!-- // List target group -->
                        <!-- List theme -->
                        <ul v-if="selectedTabId === 4" class="flex flex-wrap">
                            <li v-for="(theme, index) in model.temaer" :key="index" class="w-1/2 lg:w-1/4 py-4 pr-4">
                                <tiltak-list-item :item="theme" :type="'themes'" @clicked="selectMainMeasure" />
                            </li>
                        </ul>
                        <!-- // List theme -->
                    </div>
                </div>
                <!-- HØYRE INNHOLD-->
                <div class="flex flex-col lg:w-2/6 w-full">
                    <!-- arbeidsverktøy -->
                    <div class="w-full">
                        <article-box :header=model.toolsHeader>
                            <dl>
                                <dd v-for="item in model.tools" :key="item.id" class="pt-2">
                                    <div class="text-grey-400 hover:text-green-500">
                                        <a :href="item.href" :target="item.target" :title="item.title">
                                            <div class="flex flex-row">
                                                <div class="flex-1 text-lg text-black hover:text-green-500 underline truncate mr-2">
                                                    {{item.text}}
                                                </div>
                                                <font-awesome-icon style="margin-top: 6px;" icon="link" />
                                            </div>
                                        </a>
                                    </div>
                                </dd>
                            </dl>
                        </article-box>
                    </div>
                    <!-- // arbeidsverktøy -->
                    <!-- faglige ressurser -->
                    <div class="w-full">
                        <article-box :header=model.referencesHeader>
                            <dl>
                                <dd v-for="item in model.references" :key="item.id" class="pt-2">
                                    <div class="text-grey-400 hover:text-green-500">
                                        <a :href="item.href" :target="item.target" :title="item.title">
                                            <div class="flex flex-row">
                                                <div class="flex-1 text-lg text-black hover:text-green-500 underline truncate mr-2">
                                                    {{item.text}}
                                                </div>
                                                <font-awesome-icon style="margin-top: 6px;" icon="link" />
                                            </div>
                                        </a>
                                    </div>
                                </dd>
                            </dl>
                        </article-box>
                    </div>
                    <!-- // faglige ressurser -->
                    <!-- lokale prosjekter -->
                    <div class="w-full">
                        <article-box :header=model.projectsHeader>
                            <dl>
                                <dd v-for="item in model.projects" :key="item.id" class="pt-2">
                                    <div class="text-grey-400 hover:text-green-500">
                                        <a :href="item.href" :target="item.target" :title="item.title">
                                            <div class="flex flex-row">
                                                <div class="flex-1 text-lg text-black hover:text-green-500 underline truncate mr-2">
                                                    {{item.text}}
                                                </div>
                                                <font-awesome-icon style="margin-top: 6px;" icon="link" />
                                            </div>
                                        </a>
                                    </div>
                                </dd>
                            </dl>
                        </article-box>
                    </div>
                    <!-- // lokale prosjekter -->
                </div>
            </div>

            <div v-if="measures.length > 0 || searchEmpty === true" class="container flex flex-col lg:flex-row">
                <div class="flex flex-col lg:w-4/6 w-full pr-0 lg:pr-4">
                    <template v-if="dataLoaded">
                        <div v-if="pagination.totalPages > 1" class="w-full">
                            <Pagination :currentPage="pagination.page" :totalPages="pagination.totalPages" @change="paginate" />
                        </div>
                        <ul>
                            <li v-for="measure in paginated" :key="measure.id" class="py-2">
                                <tiltak-list-element :item="measure" />
                            </li>
                        </ul>
                        <div v-if="pagination.totalPages > 1" class="w-full mb-10">
                            <Pagination :currentPage="pagination.page" :totalPages="pagination.totalPages" @change="paginate" />
                        </div>
                    </template>
                    <LoadingSpinner v-else-if="!dataLoaded" size="2x" />
                    <message-box v-if="searchEmpty && dataLoaded && searchQuery" title="Ingen treff" :text="`Vi fant ingen tiltak på søk etter &laquo;<em>${this.searchQuery}</em>&raquo;`" type="info" />
                    <message-box v-if="error && dataLoaded" title="Feil" :text="'Det har oppstått en feil. Vennlist last siden på nytt og prøv igjen'" type="alert" />
                </div>

                <!-- Filter pane -->
                <div class="flex flex-col lg:w-2/6 w-full" :class="pagination.totalPages > 1 ? 'md:pt-10' : 'pt-2'">
                    <!--<div class="mb-8">
                        <h3 class="font-bold text-lg mb-1">Fritekstsøk</h3>
                        <form @submit.prevent="submitSearch" class="bg-white p-2">
                            <input v-model="searchQuery"
                                   type="text"
                                   placeholder="Søk på tittel, forfatter el.l."
                                   class="p-1 bg-gray-100 focus:bg-white border w-full mb-2" />
                            <button type="submit" class="bg-green-200 py-1 px-2 w-full hover:bg-blue-200">
                                Søk
                            </button>
                        </form>
                    </div>-->
                    <div class="mb-8">
                        <h3 class="font-bold text-lg mb-1">Sorter innhold</h3>
                        <div class="bg-white p-2">
                            <label for="sortAbc" class="cursor-pointer mb-2">
                                <input type="radio" name="sort" id="sortAbc" value="alphabetically" v-model="sortBy" />
                                Sorter alfabetisk
                            </label><br />
                            <label for="sortByDate" class="cursor-pointer mb-2">
                                <input type="radio" name="sort" id="sortByDate" value="date" v-model="sortBy" />
                                Sorter nyeste først
                            </label><br />
                            <label for="sortByCounty" class="cursor-pointer">
                                <input type="radio" name="sort" id="sortByCounty" value="county" v-model="sortBy" />
                                Sorter på fylke
                            </label>
                        </div>
                    </div>
                    <div class="mb-8">
                        <h3 class="font-bold text-lg mb-1">Filtrer innhold</h3>

                        <!-- Filter counties -->
                        <tiltak-filer-group :title="'Fylke / kommune'" :showFilter="filter.counties.length" @clearFilter="clearFilter('counties')">
                            <!--<tiltak-filter-county v-for="county in model.fylker" :item="county" :model="model" :selectedCounties="filter.counties" @toggled="changeFilter" :key="county.id" />-->
                            <tiltak-filter-default v-for="county in model.fylker" :item="county" :selected="filter.counties" :key="county.id" :type="'counties'" @toggled="changeFilter" />
                        </tiltak-filer-group>
                        <!-- // Filter counties -->
                        <!-- Filter target groups -->
                        <tiltak-filer-group :title="'Målgruppe'" :showFilter="filter.targetgroups.length" @clearFilter="clearFilter('targetgroups')">
                            <tiltak-filter-default v-for="targetgroup in model.maalgrupper" :item="targetgroup" :selected="filter.targetgroups" :key="targetgroup.id" :type="'targetgroups'" @toggled="changeFilter" />
                        </tiltak-filer-group>
                        <!-- // Filter target groups -->
                        <!-- Filter arenas -->
                        <tiltak-filer-group :title="'Arena'" :showFilter="filter.arenas.length" @clearFilter="clearFilter('arneas')">
                            <tiltak-filter-default v-for="arena in model.arenaer" :item="arena" :selected="filter.arenas" :key="arena.id" :type="'arenas'" @toggled="changeFilter" />
                        </tiltak-filer-group>
                        <!-- // Filter arenas -->
                        <!-- Filter themes -->
                        <tiltak-filer-group :title="'Tema'" :showFilter="filter.themes.length" @clearFilter="clearFilter('themes')">
                            <tiltak-filter-default v-for="theme in model.temaer" :item="theme" :selected="filter.themes" :key="theme.id" :type="'themes'" @toggled="changeFilter" />
                        </tiltak-filer-group>
                        <!-- // Filter themes -->
                    </div>
                </div>
                <!-- // Filter pane -->
            </div>
        </Container>
    </DefaultTemplate>
</template>

<script>
import api from '@/Scripts/api/api';
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import Container from '@/Scripts/components/atoms/Container/index.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import Pagination from '@/Scripts/components/organisms/Pagination/pagination.vue';
import TiltakListItem from '../organisms/Tiltak/HovedPage/TiltakListItem.vue';
import TiltakListItemCounty from '../organisms/Tiltak/HovedPage/TiltakListItemCounty.vue';
import TiltakListElement from '../organisms/Tiltak/TiltakListElement.vue';
import TiltakFilterCounty from '../organisms/Tiltak/HovedPage/TiltakFilterCounty.vue';
import TiltakFilterDefault from '../organisms/Tiltak/HovedPage/TiltakFilterDefault.vue';
import TiltakFilerGroup from '../organisms/Tiltak/HovedPage/TiltakFilerGroup.vue';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';
import MessageBox from '@/Scripts/components/atoms/Messagebox/MessageBox.vue';
import ArticleBox from '@/Scripts/components/atoms/ArticleBox/ArticleBox.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        ContentArea,
        Container,
        Pagination,
        TiltakListItem,
        TiltakListItemCounty,
        TiltakListElement,
        TiltakFilterCounty,
        TiltakFilterDefault,
        TiltakFilerGroup,
        LoadingSpinner,
        MessageBox,
        ArticleBox
    },
    data() {
        return {
            tabs: [
                {
                    id: 1,
                    title: 'Fylke',
                    type: 'county'
                },
                {
                    id: 2,
                    title: 'Arena',
                    type: 'arena'
                },
                {
                    id: 3,
                    title: 'Målgruppe',
                    type: 'targetgroup'

                },
                {
                    id: 4,
                    title: 'Tema',
                    type: 'theme'

                }
            ],
            selectedTabId: 3,
            measures: [],
            filter: {
                counties: [],
                targetgroups: [],
                arenas: [],
                themes: [],
            },
            sortBy: '',
            pagination: {
                page: 1,
                pageSize: this.model.itemsPrPage,
                totalPages: Math.ceil(this.model.tiltakItems.length / this.model.itemsPrPage)
            },
            searchQuery: null,
            dataLoaded: true,
            siteId: this.$store.getters.getSiteId,
            searchEmpty: false,
            error: false
        };
    },
    methods: {
        handleClear() {
            this.measures = [];
            this.searchEmpty = false;
            this.searchQuery = '';

        },
        handleSearch(event) {
            this.submitSearch();
        },
        submitSearch() {
            this.dataLoaded = false;

            const parameters = {
                size: this.pagination.pageSize,
                page: this.pagination.page,
                siteid: this.$store.getters.getSiteId,
                words: this.searchQuery
            };

            api.SearchMessures(parameters).then(response => {
                if (response.status === 200) {
                    this.dataLoaded = true;
                    if (response.data.length > 0) {
                        this.searchEmpty = false;
                        this.measures = response.data;
                        this.recalculatePagination();
                    } else {
                        this.measures = [];
                        this.searchEmpty = true;
                        this.recalculatePagination();
                        this.addAllCountiesToFilter();
                    }
                } else {
                    this.error = true;
                    this.dataLoaded = true;
                    this.measures = [];
                    this.searchEmpty = true;
                    this.recalculatePagination();
                }
            });
        },
        changeTab(tabId) {
            const currentQuery = { ...this.$route.query };
            currentQuery.tab = tabId;
            this.$router.push({ query: currentQuery });

            this.selectedTabId = tabId;
            this.measures = [];
        },
        selectMainMeasure(element) {
            // Clear default counties
            if (element.type === 'counties') {
                this.filter.counties = [];
            }

            // Clear search
            this.searchQuery = '';

            const currentQuery = { ...this.$route.query };

            this.filter[element.type].push(element.item.id);
            this.measures = this.model.tiltakItems.filter(measure => {
                if (element.type === 'counties') {
                    currentQuery.county = element.item.id;
                    return this.filter.counties.includes(measure.fylkeId);
                }

                if (element.type === 'arenas') {
                    // currentQuery.arena = element.item.id;
                    return measure.arena.includes(element.item.id);
                }

                if (element.type === 'targetgroups') {
                    // currentQuery.targetgroup = element.item.id;
                    return measure.malGruppe.includes(element.item.id);
                }

                if (element.type === 'themes') {
                    // currentQuery.theme = element.item.id;
                    return measure.tema.includes(element.item.id);
                }
            });

            this.$router.push({ query: currentQuery });
            this.recalculatePagination();
        },
        addAllCountiesToFilter() {
            //this.model.fylker.map(county => {
            //    this.filter.counties.push(county.id);
            //});
        },
        clearFilter(type) {
            this.filter[type] = [];
            const element = {
                item: [],
                type: type
            };
            this.changeFilter(element);
        },
        changeFilter(element) {
            let arr = this.filter[element.type];
            if (arr.includes(element.item.id)) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] === element.item.id) {
                        arr.splice(i, 1);
                    }
                }
                this.filter[element.type] = arr;
            } else {
                this.filter[element.type].push(element.item.id);
            }

            // Temporary array of measures
            let measuresTemp = [];

            // Loop through filter and add measures to temporary array
            for (const [key, value] of Object.entries(this.filter)) {
                this.model.tiltakItems.filter(measure => {
                    if (key === 'counties' && value.length > 0) {
                        if (this.filter.counties.includes(measure.fylkeId)) {
                            measuresTemp.push(measure);
                        }
                    }

                    if (key === 'targetgroups' && value.length > 0) {
                        if (measure.malGruppe.some(r => this.filter.targetgroups.indexOf(r) >= 0)) {
                            measuresTemp.push(measure);
                        }
                    }

                    if (key === 'arenas' && value.length > 0) {
                        if (measure.arena.some(r => this.filter.arenas.indexOf(r) >= 0)) {
                            measuresTemp.push(measure);
                        }
                    }

                    if (key === 'themes' && value.length > 0) {
                        if (measure.tema.some(r => this.filter.themes.indexOf(r) >= 0)) {
                            measuresTemp.push(measure);
                        }
                    }
                });
            }

            // Add temporary array / remove duplicates
            this.measures = this.removeDuplicates(measuresTemp, 'name');
            this.recalculatePagination();
        },
        removeDuplicates(array, property) {
            const uniqueMap = new Map();
            return array.filter(obj => !uniqueMap.has(obj[property]) && uniqueMap.set(obj[property], 1));
        },
        sortMeasures(key, order = 'asc') {
            return function innerSort(a, b) {
                if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                    // property doesn't exist on either object
                    return 0;
                }

                const varA = (typeof a[key] === 'string')
                    ? a[key].toUpperCase() : a[key];
                const varB = (typeof b[key] === 'string')
                    ? b[key].toUpperCase() : b[key];

                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        },
        paginate(direction) {
            (direction < this.pagination.page) ? this.prev() : this.next();
        },
        prev() {
            this.pagination.page--;
        },
        next() {
            this.pagination.page++;
        },
        recalculatePagination() {
            if (this.measures.length > 0) {
                this.pagination.totalPages = Math.ceil(this.measures.length / 10);
            } else {
                this.pagination.totalPages = 1;
            }
        },
    },
    computed: {
        indexStart() {
            return (this.pagination.page - 1) * this.pagination.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pagination.pageSize;
        },
        paginated() {
            return this.measures.slice(this.indexStart, this.indexEnd);
        }
    },
    mounted() {
        const currentQuery = { ...this.$route.query };

        let tabParam = this.$route.query.tab;
        if (tabParam === undefined) {
            tabParam = 3;
        } else {
            tabParam = parseInt(tabParam);
            this.selectedTabId = tabParam;
        }
        currentQuery.tab = tabParam;

        let countyParam = this.$route.query.county;
        if (countyParam === undefined) {
            this.addAllCountiesToFilter();
        } else {
            countyParam = parseInt(countyParam);
            let fylke = this.model.fylker.find(item => item.id === countyParam);
            this.selectMainMeasure({ type: 'counties', item: fylke});
        }
        this.$router.push({ query: currentQuery });
    },
    watch: {
        sortBy(val) {
            if (val === 'county') {
                this.measures = this.measures.sort(this.sortMeasures('fylkeNavn'));
            }

            if (val === 'date') {
                this.measures = this.measures.sort(this.sortMeasures('regDate'));
            }

            if (val === 'alphabetically') {
                this.measures = this.measures.sort(this.sortMeasures('name'));
            }
        }
    }
};
</script>

<style>
    .content p {
        margin-bottom: 1rem;
    }
</style>
