.footer a {
    @apply underline;
}

.footer a:hover {
    @apply text-green-400;
}

.c-social__links {
    @apply mb-8;
}
